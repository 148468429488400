import React from 'react'
import './Quote.scss'

const Quote = ({ quote, author }) => {
  return (
    <>
      <p className="quote-text body-medium">{quote}</p>
      <p className="quote-author">{author}</p>
    </>
  )
}

export default Quote
