import React, { FC, HTMLProps } from 'react'
import classNames from 'classnames'
import './Button.scss'

type ButtonProps = {
  loading?: boolean
  disabled?: boolean
  buttonSize?: 'large'
  buttonType?: 'primary' | 'link' | 'inverted'
  type?: 'button' | 'submit' | 'reset'
}

const Button: FC<ButtonProps & HTMLProps<HTMLButtonElement>> = ({
  children,
  className,
  buttonType = 'primary',
  buttonSize,
  loading,
  disabled,
  ...otherProps
}) => {
  const buttonClass = classNames(`btn btn--${buttonType} ${className ? className : ''}`, {
    'btn--size-lg': buttonSize === 'large',
  })
  const textClass = classNames('btn__text', { 'btn__text--hide': loading })
  return (
    <button className={buttonClass} disabled={disabled} {...otherProps}>
      {loading && <span className="btn__spinner"></span>}
      <span className={textClass}>{children}</span>
    </button>
  )
}

export default Button
