import React, { CSSProperties } from 'react'
import { navigate } from 'gatsby'
import { sendAnalyticsEvent } from '../utils/ga'
import './CategoryCard.scss'

type CategoryCardProps = {
  title: string
  sampleQuestion: string
  category: string
  image?: string
}

const CategoryCard = ({ title, sampleQuestion, category, image }: CategoryCardProps) => {
  const onClick = () => {
    sendAnalyticsEvent('click', { label: `category-card-${category}` })
    navigate(`/simulator#category=${category}`)
  }

  let style: CSSProperties = {}
  if (image) {
    style = {
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    }
  }

  return (
    <div className="category-card" onClick={onClick} style={style}>
      <div className="category-card__dark-background">
        <div className="category-card__question">
          <p className="body-large">{sampleQuestion}</p>
        </div>
        <div className="category-card__title">
          <p className="body-medium">{title}</p>
        </div>
      </div>
    </div>
  )
}

export default CategoryCard
