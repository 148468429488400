import React from 'react'
import './FooterMinimal.scss'

const FooterMinimal = () => {
  return (
    <div className="section-footer-minimal section-outer--gray">
      <div className="section-inner vertical-indent--xs">
        <span className="section-footer-minimal__copyright">© ООО Точка речи, 2021</span>
      </div>
    </div>
  )
}

export default FooterMinimal
