const IS_SUBSCRIBED_TO_UPDATES = 'isSubscribedToUpdates'
const HAS_MEDIA_PERMISSIONS = 'hasMediaPermissions'

const stringToBoolean = (value: string | null | undefined) => {
  if (value === null || value === undefined) return false

  switch (value.toLowerCase()) {
    case 'false':
    case 'no':
    case '0':
    case '':
      return false
    default:
      return true
  }
}

const getIsSubscribedToUpdates = () => {
  if (typeof window === 'undefined') return false
  const storageValue = localStorage.getItem(IS_SUBSCRIBED_TO_UPDATES)
  return stringToBoolean(storageValue)
}

const setIsSubscribedToUpdates = () => {
  localStorage.setItem(IS_SUBSCRIBED_TO_UPDATES, 'true')
}

const removeIsSubscribedToUpdates = () => {
  localStorage.removeItem(IS_SUBSCRIBED_TO_UPDATES)
}

const getHasMediaPermissions = () => {
  if (typeof window === 'undefined') return false

  const storageValue = localStorage.getItem(HAS_MEDIA_PERMISSIONS)
  return stringToBoolean(storageValue)
}

const setHasMediaPermissions = () => {
  localStorage.setItem(HAS_MEDIA_PERMISSIONS, 'true')
}

const removeHasMediaPermissions = () => {
  localStorage.removeItem(HAS_MEDIA_PERMISSIONS)
}

export {
  getIsSubscribedToUpdates,
  setIsSubscribedToUpdates,
  removeIsSubscribedToUpdates,
  getHasMediaPermissions,
  setHasMediaPermissions,
  removeHasMediaPermissions,
}
