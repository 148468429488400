type AnalyticsData = {
  label: string
  category?: string
  value?: string
}

function sendAnalyticsEvent(action, { label, category = 'engagement', value }: AnalyticsData) {
  if (!window.gtag) return

  const analyticsData = {
    event_category: category,
    event_label: label,
    value: value,
  }

  window.gtag('event', action, { ...analyticsData })
}

export { sendAnalyticsEvent }
