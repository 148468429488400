import React from 'react'
import { Link } from 'gatsby'
import LogoSvg from '../images/svgs/logo.svg'
import LogoBrightSvg from '../images/svgs/logo-bright.svg'
import './Logo.scss'

type LogoProps = {
  bright?: boolean
}

const Logo = ({ bright }: LogoProps) => {
  return (
    <Link className="logo" to="/">
      {bright ? <LogoBrightSvg /> : <LogoSvg />}
    </Link>
  )
}

export default Logo
