import React, { useEffect, useState, useRef } from 'react'
import { sendAnalyticsEvent } from '../utils/ga'
import './HorizontalList.scss'

const SCROLL_AMOUNT_PX = 280
const SCROLL_EXTENDER_PX = 5

const HorizontalList = ({ children }) => {
  const wrapperRef = useRef(null)
  const categoriesRef = useRef(null)
  const [enableRightScrollButton, setEnableRightScrollButton] = useState(false)
  const [enableLeftScrollButton, setEnableLeftScrollButton] = useState(false)
  const [showButtons, setShowButtons] = useState(false)

  const [scrollLength, setScrollLength] = useState(0)

  useEffect(() => {
    if (categoriesRef?.current) {
      const { current } = categoriesRef
      if (current.scrollWidth > current.clientWidth) {
        setScrollLength(current.scrollWidth - current.clientWidth)
        setEnableRightScrollButton(true)
      }
    }
  }, [categoriesRef?.current])

  useEffect(() => {
    if (wrapperRef?.current && categoriesRef?.current) {
      if (wrapperRef?.current.scrollWidth < categoriesRef?.current.scrollWidth - SCROLL_EXTENDER_PX) {
        setShowButtons(true)
      }
    }
  }, [wrapperRef?.current, categoriesRef?.current])

  const scrollToTheRight = () => {
    if (!categoriesRef?.current) return
    sendAnalyticsEvent('click', { label: 'scroll-to-right' })
    categoriesRef.current.scrollTo({ left: categoriesRef.current.scrollLeft + SCROLL_AMOUNT_PX, behavior: 'smooth' })
    if (categoriesRef.current.scrollLeft + SCROLL_AMOUNT_PX >= scrollLength) {
      setEnableRightScrollButton(false)
    }
    setEnableLeftScrollButton(true)
  }

  const scrollToTheLeft = () => {
    if (!categoriesRef?.current) return
    sendAnalyticsEvent('click', { label: 'scroll-to-left' })
    categoriesRef.current.scrollTo({ left: categoriesRef.current.scrollLeft - SCROLL_AMOUNT_PX, behavior: 'smooth' })
    if (categoriesRef.current.scrollLeft - SCROLL_AMOUNT_PX <= 0) {
      setEnableLeftScrollButton(false)
    }
    setEnableRightScrollButton(true)
  }

  const onCategoriesScroll = () => {
    if (categoriesRef.current.scrollLeft + 1 >= scrollLength) {
      setEnableRightScrollButton(false)
      setEnableLeftScrollButton(true)
    } else if (categoriesRef.current.scrollLeft - 1 <= 0) {
      setEnableRightScrollButton(true)
      setEnableLeftScrollButton(false)
    } else {
      setEnableRightScrollButton(true)
      setEnableLeftScrollButton(true)
    }
  }

  return (
    <div ref={wrapperRef} className="horizontal-list__wrapper">
      {showButtons && (
        <div
          className={`horizontal-list__scroll-button horizontal-list__scroll-button--left ${
            enableLeftScrollButton ? '' : 'horizontal-list__scroll-button--disabled'
          }`}
          onClick={scrollToTheLeft}
        >
          <i className="horizontal-list__scroll-button__arrow horizontal-list__scroll-button__arrow--left"></i>
        </div>
      )}
      <section className="horizontal-list__list" ref={categoriesRef} onScroll={onCategoriesScroll}>
        {children}
        <div className="horizontal-list__scroll-extender"></div>
      </section>
      {showButtons && (
        <div
          className={`horizontal-list__scroll-button horizontal-list__scroll-button--right ${
            enableRightScrollButton ? '' : 'horizontal-list__scroll-button--disabled'
          }`}
          onClick={scrollToTheRight}
        >
          <i className="horizontal-list__scroll-button__arrow horizontal-list__scroll-button__arrow--right"></i>
        </div>
      )}
    </div>
  )
}

export default HorizontalList
