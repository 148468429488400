import React from 'react'
import { Link } from 'gatsby'
import Logo from './Logo'
import './Footer.scss'

const Footer = () => {
  return (
    <footer className="section-outer--dark-gray section-footer">
      <div className="section-inner vertical-indent--s">
        <div className="section-footer-logo-wrapper">
          <Logo bright={true} />
        </div>
        <div className="section-footer-menu">
          <Link className="section-footer-menu-item" to="/about">
            О проекте
          </Link>
          {/* <Link className="section-footer-menu-item" to="/privacy-policy">
            Политика конфиденциальности
          </Link> */}
        </div>
        <div className="section-footer-menu-contact-email">hello@tochkarechi.ru</div>
        <div className="section-footer-menu-copyright">© ООО Точка речи, 2021</div>
      </div>
    </footer>
  )
}

export default Footer
