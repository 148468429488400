import React, { useState } from 'react'
import { navigate } from 'gatsby'
import classNames from 'classnames'
import { Link } from 'gatsby'
import Logo from './Logo'
import './Navbar.scss'

type NavbarProps = {
  landing?: boolean
}

const Navbar = ({ landing = false }: NavbarProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const sectionOuterClass = classNames('section-navbar', { 'section-outer--gray': landing })
  const menuClass = classNames('navbar-menu', { 'navbar-menu--gray': landing, 'navbar-menu--open': isOpen })

  return (
    <header className={sectionOuterClass}>
      <div className="section-inner section-inner vertical-indent--s">
        <div>
          <Logo />
          <div>Развитие навыков общения и речи</div>
        </div>
        <div className="navbar-hamburger" onClick={() => setIsOpen(!isOpen)}>
          <span />
          <span />
          <span />
        </div>
        <nav className={menuClass}>
          <Link to="/categories" className="navbar-menu-menu-item" activeClassName="navbar-menu-menu-item--active">
            Категории вопросов
          </Link>
          <Link to="/about" className="navbar-menu-menu-item" activeClassName="navbar-menu-menu-item--active">
            О проекте
          </Link>
          {/* {getAuthMenu()} */}
        </nav>
      </div>
    </header>
  )
}

export default Navbar
